import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordView = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtVerifyPage = lazy(() => import('src/pages/auth/jwt/verify'));
const JwtResetPasswordPage = lazy(() => import('src/pages/auth/jwt/reset-password'));
const JwtOTPVerifyPage = lazy(() => import('src/pages/auth/jwt/otp-verify'));

// ----------------------------------------------------------------------

const authJwt = {
  // path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout show>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout show>
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <AuthClassicLayout show={false} isForgetPassword>
          <JwtForgotPasswordView />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <AuthClassicLayout show={false}>
          <JwtResetPasswordPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'verify',
      element: (
        // <AuthClassicLayout show={false}>
        <JwtVerifyPage />
        // </AuthClassicLayout>
      ),
    },
    {
      path: 'otp-verify',
      element: (
        <AuthClassicLayout>
          <JwtOTPVerifyPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: '',
    children: [authJwt],
  },
];
