export const steps = [
  {
    target: '.header', // Element in the Header component
    title: 'Manage Account Settings',
    content:
      'Here, you can manage your account settings such as personal details, password, top-up options, and view your profile.',
    disableBeacon: true,
  },
  {
    target: '.settings', // Element in the NavVertical component
    title: 'Personalize Your Experience',
    content:
      'Customize the app’s theme to match your style preferences and create a more personalized user experience.',
  },
  {
    target: '.courses', // Element in the NavVertical component
    title: 'Navigate Courses',
    content: 'Use this sidebar to view all courses',
  },
  {
    target: '.ai', // Element in the My Care page
    title: 'Manage Your AI',
    content: 'On the "AI" page, you can view a whole lot',
  },

  // {
  //   target: '.my-physician', // Element in the My Physician navigation
  //   title: 'Primary Care Doctor',
  //   content:
  //     'This section allows you to view your primary care doctor’s profile, chat with them, and book appointments directly.',
  // },
  // {
  //   target: '.find-specialist', // Element in the Find a Specialist navigation
  //   title: 'Find a Specialist',
  //   content:
  //     'Here, you can access a list of specialists organized by categories and easily book appointments with the specialist you need.',
  // },
];

export const stepsMini = [
  {
    target: '.header',
    title: 'Manage Account Settings',
    content:
      'Here, you can manage your account settings such as personal details, password, top-up options, and view your profile.',
    disableBeacon: true,
  },
  {
    target: '.settings',
    title: 'Personalize Your Experience',
    content:
      'Customize the app’s theme to match your style preferences and create a more personalized user experience.',
  },
  {
    target: '.side-nav',
    title: 'Navigation Overview',
    content: 'Use this sidebar to navigate the app. You can ',
  },
];
