import Stack from '@mui/material/Stack';
// import Button from "@mui/material/Button";
import { Avatar, Button, Divider, ListItemText } from '@mui/material';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useAuthContext } from 'src/auth/hooks';
import { useRouter } from 'src/routes/hooks';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// locales
// import { useLocales } from "src/locales";
import NavItem from 'src/components/nav-section/vertical/nav-item';
import { paths } from 'src/routes/paths';
import Iconify from 'src/components/iconify';
import { navVerticalConfig } from 'src/components/nav-section/config';

// ----------------------------------------------------------------------

const bottomNavItem = [
  {
    title: 'Profile',
    path: paths.dashboard.user.profile,
    icon: <Iconify icon="mdi:user-circle" />,
    disabled: false,
  },
  {
    title: 'Account Settings',
    path: paths.dashboard.user.account,
    icon: <Iconify icon="streamline:cog-solid" />,
    disabled: false,
  },
];

// const logoutNavItem = {
//   title: "Logout",
//   path: "#",
//   icon: <Iconify icon="streamline:button-power-1-solid" />,
//   disabled: false,
// };

// ----------------------------------------------------------------------

export default function NavBottom() {
  const { logout, user } = useAuthContext();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const popover = usePopover();
  const confirm = useBoolean();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  // const { t } = useLocales();

  const config = navVerticalConfig({
    currentRole: 'admin',
  });

  return (
    <>
      <Stack
        sx={{
          px: 2,
          py: 4,
          textAlign: 'center',
        }}
      >
        <Divider />
        <Stack flexDirection="row" alignItems="center" pt={2} px={2}>
          <Avatar src={user?.photo} />
          <Stack alignItems="center">
            <Button variant="text" onClick={confirm.onTrue}>
              Logout
            </Button>
          </Stack>

          {/* <ListItemText
            primary={`${user?.firstName || ''} ${user?.lastName || ''}`}
            secondary={user?.email}
            primaryTypographyProps={{
              typography: 'body2',
              // color: '#fff',
              textAlign: 'left',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              textAlign: 'left',
            }}
          /> */}
        </Stack>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Logout"
        content={<>Are you sure want to logout?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleLogout();
              confirm.onFalse();
            }}
          >
            Yes
          </Button>
        }
      />
    </>
  );
}
