import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const authContext = useAuthContext();
  const userPackage = authContext?.user?.userPackage?.toLowerCase() || '';

  const data = useMemo(
    () => [
      {
        // subheader: t('quick links'),
        items: [
          {
            title: t('Home'),
            path: paths.dashboard.root,
            icon: <Iconify icon="bitcoin-icons:home-outline" />,
            disabled: false,
            className: 'home',
          },
          {
            title: t('Courses'),
            path: paths.dashboard.course.root,
            icon: <Iconify icon="solar:notes-outline" />,
            className: 'course',
            // children: [
            //   { title: t('Courses'), path: paths.dashboard.course.root },
            //   { title: t('details'), path: paths.dashboard.course.demo.details },
            //   // { title: t('test'), path: paths.dashboard.course.demo.details },
            // ],
          },
          {
            title: t('AI'),
            path: paths.dashboard.ai,
            icon: <Iconify icon="bi:robot" />,
            disabled: false,
            className: 'vitals',
          },
          {
            title: 'Profile',
            path: paths.dashboard.user.root,
            icon: <Iconify icon="iconamoon:profile-thin" />,
            disabled: false,
            children: [
              { title: t('Edit Profile'), path: paths.dashboard.user.root },
              { title: t('Refer a friend'), path: paths.dashboard.user.refer },
              // { title: t('Contact Us'), path: paths.dashboard.user.contact },
              // { title: t('FAQ'), path: paths.dashboard.user.faq },
              { title: t('Scoreboard & History'), path: paths.dashboard.user.score },
            ],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
