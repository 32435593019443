import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

export default function ExamsLayout({ children }) {
  return <>{children}</>;
}

ExamsLayout.propTypes = {
  children: PropTypes.node,
};
