import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
export const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const CourseDetailsPage = lazy(() => import('src/pages/dashboard/courses/details'));
const CourseListPage = lazy(() => import('src/pages/dashboard/courses/list'));
const CourseTestPage = lazy(() => import('src/pages/dashboard/courses/test'));
const CourseTopicPage = lazy(() => import('src/pages/dashboard/courses/topic'));
const OverviewAiPage = lazy(() => import('src/pages/dashboard/ai'));
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const FaqPage = lazy(() => import('src/pages/dashboard/user/faq'));
const ReferPage = lazy(() => import('src/pages/dashboard/user/refer'));
const ScoreHistoryPage = lazy(() => import('src/pages/dashboard/user/score'));
const ContactPage = lazy(() => import('src/pages/dashboard/user/contact'));

// TEST RENDER PAGE BY ROLE
// const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));

// BLANK PAGE
// const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ai', element: <OverviewAiPage /> },
      {
        path: 'course',
        children: [
          { element: <CourseListPage />, index: true },
          { path: 'list', element: <CourseListPage /> },
          { path: ':courseId', element: <CourseDetailsPage /> },
          { path: ':courseId/test/:testId', element: <CourseTestPage /> },
          { path: ':courseId/topic/:testId', element: <CourseTopicPage /> },
        ],
      },
      {
        path: 'profile',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'refer', element: <ReferPage /> },
          { path: 'contact', element: <ContactPage /> },
          { path: 'faq', element: <FaqPage /> },
          { path: 'score', element: <ScoreHistoryPage /> },
        ],
      },
    ],
  },
];
