import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import ExamsLayout from 'src/layouts/exam/layout';

// ----------------------------------------------------------------------

// export const HomePage = lazy(() => import('src/pages/home'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const ExamPage = lazy(() => import('src/pages/quiz/exam'));
const ResultPage = lazy(() => import('src/pages/quiz/result'));
const ReviewPage = lazy(() => import('src/pages/quiz/review'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [{ path: '404', element: <Page404 /> }],
  },
  {
    element: (
      <ExamsLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </ExamsLayout>
    ),
    children: [
      { path: 'exam/:testId', element: <ExamPage /> },
      { path: 'result/:testId', element: <ResultPage /> },
      { path: 'review/:testId', element: <ReviewPage /> },
    ],
  },
];
