import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// import { Typography } from '@mui/material';
import { useResponsive } from 'src/hooks/use-responsive';
import { AuthImage } from 'src/assets/images';
import Logo from 'src/components/logo';
// import Iconify from 'src/components/iconify';

// import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, isForgetPassword }) {
  const upMd = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        flex: '50%',
        display: 'flex',
        height: 'auto',
        minHeight: 'min-content',
        background: '#FCF6FF',
        position: 'relative',
        px: 5,
        py: 5,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          // my: '25px',
          width: { xs: '100%', sm: '425px', xl: '500px' },
          maxWidth: { xs: '425px', xl: '500px' },
        }}
      >
        {children}
      </Box>
    </Stack>
  );

  const renderSection = (
    <Stack
      sx={{
        width: '50%',
        height: '100%',
        px: 5,
        py: 5,
        overflow: 'hidden',
        position: 'fixed',
      }}
    >
      <Box>
        <Logo />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          component="img"
          src={AuthImage}
          alt="Login"
          sx={{
            objectFit: 'contain',
            width: { sx: '100%', lg: '85%' },
            height: 'auto',
            objectPosition: 'center',
          }}
        />
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        height: '100%',
        // minHeight: '100vh',
        width: '100vw',
      }}
    >
      {upMd && renderSection}
      {upMd && <Stack sx={{ flex: '50%' }} />}
      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  isForgetPassword: PropTypes.bool,
};
