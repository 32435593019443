// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  privacy: '/privacy',
  termsCondition: '/terms-condition',
  exam: (testId, type) => `/exam/${testId}?type=${type}`,
  result: (testId) => `/result/${testId}`,
  review: (testId) => `/review/${testId}`,
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `/login`,
      register: `/register`,
      forgotPassword: `/forgot-password`,
      verify: `/verify`,
      resetPassword: `/reset-password`,
      otpVerify: `/otp-verify`,
      userSelection: `/user-selection`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    ai: `${ROOTS.DASHBOARD}/ai`,
    // quiz: `${ROOTS.DASHBOARD}/quiz`,
    quiz: (quizId) => `${ROOTS.DASHBOARD}/quiz/${quizId}`,
    course: {
      root: `${ROOTS.DASHBOARD}/course`,
      details: (courseId) => `${ROOTS.DASHBOARD}/course/${courseId}`,
      test: (courseId, testId) => `${ROOTS.DASHBOARD}/course/${courseId}/test/${testId}`,
      topic: (courseId, testId) => `${ROOTS.DASHBOARD}/course/${courseId}/topic/${testId}`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/profile`,
      refer: `${ROOTS.DASHBOARD}/profile/refer`,
      contact: `${ROOTS.DASHBOARD}/profile/contact`,
      faq: `${ROOTS.DASHBOARD}/profile/faq`,
      score: `${ROOTS.DASHBOARD}/profile/score`,
    },
  },
};
